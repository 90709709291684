import { reactive } from 'vue'

type UseBreakpointsUnits = number | [number?, number?]

type UseBreakpointsOptions = {
  [k: string]: UseBreakpointsUnits
}

export enum UseBreakpointsEvents {
  ENTER = 'enter',
  LEAVE = 'leave',
}

type UseBreakpointsSubscribe<R> = (
  event: UseBreakpointsEvents,
  handler: UseBreakpointsListener
) => R

type UseBreakpointsUnsubscribe = () => void

type UseBreakpointsResult = {
  matches: boolean
  on: UseBreakpointsSubscribe<UseBreakpointsUnsubscribe>
  off: UseBreakpointsSubscribe<void>
}

type UseBreakpointsListener = (e: MediaQueryListEvent) => void

const isValidUnit = (unit?: UseBreakpointsUnits): unit is number =>
  typeof unit === 'number' && isFinite(unit)

function isValidBreakpoints(breakpoints: UseBreakpointsOptions): boolean {
  return Object.values(breakpoints).every((bp) =>
    Array.isArray(bp)
      ? bp.length <= 2 &&
        bp.length > 0 &&
        bp.every(
          (unit) =>
            ['number', 'undefined'].includes(typeof unit) || unit === null
        ) &&
        bp.some(isValidUnit)
      : isValidUnit(bp)
  )
}

function resolveMediaUnits(units: UseBreakpointsUnits): string {
  if (isValidUnit(units)) {
    return `screen and (max-width: ${units}px)`
  } else {
    const width = ['min-width', 'max-width']

    return units
      .map((unit, index) =>
        isValidUnit(unit) ? `(${width[index]}: ${unit}px)` : ''
      )
      .filter(Boolean)
      .join(' and ')
  }
}

export function useBreakpoints<B extends UseBreakpointsOptions>(
  breakpoints: B
): {
  [K in keyof B]: UseBreakpointsResult
} {
  if (!isValidBreakpoints(breakpoints)) {
    throw new Error(
      '"useBreakpoints" options should be an object with values: number | [number?, number?]'
    )
  }

  type Return = { [K in keyof B]: UseBreakpointsResult }

  const entries: [k: keyof B, v: UseBreakpointsUnits][] =
    Object.entries(breakpoints)

  const points: Return = entries.reduce((result, [alias, units]) => {
    const media = window.matchMedia(resolveMediaUnits(units))

    const events: {
      [K in UseBreakpointsEvents]: UseBreakpointsListener[]
    } = {
      [UseBreakpointsEvents.ENTER]: [],
      [UseBreakpointsEvents.LEAVE]: [],
    }

    result[alias] = {
      matches: media.matches,
      on(event, handler): () => void {
        events[event].push(handler)

        return () => result[alias].off(event, handler)
      },
      off(event, handler) {
        const index = events[event].indexOf(handler)
        if (index !== -1) events[event].splice(index, 1)
      },
    }

    const onChange = (e: Event): void => {
      const event = e as MediaQueryListEvent
      const callbacks = event.matches ? events.enter : events.leave

      callbacks.forEach((callback) => callback(event))

      result[alias].matches = event.matches
    }

    media.addEventListener('change', onChange)

    return result
  }, reactive({}) as Return)

  return points as Return
}

export const useMediaBreakpoints = () => useBreakpoints({ md: 800 })
